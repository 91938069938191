/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService, combineHeadersOptions, commonErrorHandlingDisabled,
  globalLoadingDisabled,
} from '../abstract/abstract.server.interaction.service';
import { countriesNames } from '../../../common/abstract.components/constants';
import { Observable, map, of, catchError } from 'rxjs';
import { getRawBase64Data, getFormatedSsn } from '../../../util';
import { AppService } from '../../../../app.service';

@Injectable()
export class AgreementService extends AbstractServerInteractionService {
  protected get url(): {
    terms: Function,
    options: Function,
    check: Function,
    send: Function,
    pastEmployeeFormData: string,
    taskInfo: string,
    currentUserAgreement: string,
  } {
    return this.appService.getApiUrls().agreement;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getTaskInfo() {
    return this.http.get(this.url.taskInfo);
  }

  public getTerms(id: string, loaderEnabled: boolean = true) {
    return this.http.get(
      this.url.terms(id),
      loaderEnabled
        ? { headers: combineHeadersOptions(commonErrorHandlingDisabled)}
        : {headers: combineHeadersOptions(globalLoadingDisabled, commonErrorHandlingDisabled)}
    );
  }

  public getPastEmployeeFormModel(): Observable<any> {
    return this.http.get(
      this.url.pastEmployeeFormData,
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    );
  }

  public getOptions(id: string): Observable<any> {
    return this.http.get(
      this.url.options(id),
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    );
  }

  public checkAgreement(id) {
    return this.http.get(
      this.url.check(id),
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    );
  }

  public sendAgreementForm(id, formData, country) {
    const data = this.formatForCountry(
      country,
      this.getInitialAgreementData(formData)
    );

    return this.http.post(
      this.url.send(id),
      data,
      {headers: combineHeadersOptions(globalLoadingDisabled, commonErrorHandlingDisabled)}
    ).pipe(map(() => true));
  }

  public fetchPastEmployeeAgreement(): Observable<any> {
    return this.http.get(
      this.url.currentUserAgreement,
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    ).pipe(catchError(() => of(false)));
  }

  private formatCanadaAgreement(initialFormData: IAgreementFormData): IAgreementFormData {
    const result = { ...initialFormData };

    result.provinceId = initialFormData.stateId;
    result.stateId = null;

    return result;
  }

  private formatUSAAgreement(initialFormData: IAgreementFormData): IAgreementFormData {
    return initialFormData;
  }

  private formatForCountry(country: string, initialData: IAgreementFormData): IAgreementFormData {
    const countrySpecificFormats = {
      [countriesNames.canada]: this.formatCanadaAgreement,
      [countriesNames.us]: this.formatUSAAgreement,
    };

    return countrySpecificFormats[country](initialData);
  }

  private getInitialAgreementData(formData): IAgreementFormData {
    return {
      userType: formData.userType,
      housing: formData.housing,
      firstName: formData.legalFirstName,
      preferredFirstName: formData.preferredFirstName,
      middleName: formData.legalMiddleName,
      lastName: formData.legalLastName,
      dateOfBirth: formData.dateOfBirth,
      email: formData.email,
      preferredPhone: {
        phoneNumber: formData.primaryPhone,
        typeId: formData.primaryPhoneType
      },
      alternatePhone: !formData.alternatePhone ? null : {
        phoneNumber: formData.alternatePhone,
        typeId: formData.alternatePhoneType
      },
      school: formData.college,
      addressLine: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.city,
      stateId: formData.countryState,
      zip: formData.postalCode,
      emergencyContact: {
        firstName: formData.emergencyFirstName,
        lastName: formData.emergencyLastName,
        primaryPhoneNumber: {
          phoneNumber: formData.emergencyPrimaryPhone,
          typeId: formData.emergencyPrimaryPhoneType,
        },
        alternatePhoneNumber: formData.emergencyAlternatePhone ? {
          phoneNumber: formData.emergencyAlternatePhone,
          typeId: formData.emergencyAlternatePhoneType,
        } : null,
        relationshipId: formData.emergencyRelationship
      },
      signature: getRawBase64Data(formData.signature),
      ssn: getFormatedSsn(formData.ssn),
      genderId: formData.gender,
      ethnicityId: formData.ethnicity,
      raceId: formData.race,
      citizenshipId: formData.citizenship,
      maritalStatusId: formData.maritalStatus,
      militaryStatusId: formData.militaryStatus,
    };
  }
}

export interface IAgreementFormData {
  userType: string;
  housing: number| string | null;
  firstName: string;
  preferredFirstName: string | null;
  middleName: string | null;
  lastName: string;
  dateOfBirth: string;
  email: string;
  preferredPhone: {
    phoneNumber: string;
    typeId: number;
  };
  alternatePhone: {
    phoneNumber: string;
    typeId: number;
  };
  school: string;
  addressLine: string;
  addressLine2: string;
  city: string;
  stateId?: string;
  provinceId?: string;
  zip: string;
  emergencyContact: {
    firstName: string;
    lastName: string;
    primaryPhoneNumber: {
      phoneNumber: string;
      typeId: number;
    };
    alternatePhoneNumber: {
      phoneNumber: string;
      typeId: number;
    } | null;
    relationshipId: number;
  };
  signature: string;
  ssn: string;
  genderId: string;
  ethnicityId: string;
  raceId: string;
  citizenshipId: string;
  maritalStatusId: string;
  militaryStatusId: string | null;
}

export interface IAgreementFormModel {
  firstName: string;
  preferredFirstName: string | null;
  middleName: string | null;
  lastName: string;
  email: string;
  school: string | null;
  dateOfBirth: string;
  address: {
    zip: number;
    addressLine: string;
    addressLine2: string | null;
    city: string;
    state: string | null;
    stateId: number | null;
    province: string | null;
    provinceId: number | null;
  };
  preferredPhone: {
    phoneNumber: string;
    typeId: number;
  };
  alternatePhone: {
    phoneNumber: string;
    typeId: number;
  } | null;
  demographicDetails: {
    gender: string;
    ethnicity: string;
    race: string;
    citizenship: string;
    maritalStatus: string;
    militaryStatus: string;
  };
  emergencyContact: {
    firstName: string;
    lastName: string;
    primaryPhoneNumber: {
      phoneNumber: string;
      typeId: number;
    };
    alternateEmergencyPhone: {
      phoneNumber: string;
      typeId: number;
    } | null;
    relationshipId: number;
    relationship: string;
  };
}

export interface IPastEmployeeStepOneFormData {
  gender: string;
  ethnicity: string;
  race: string;
  citizenship: string;
  maritalStatus: string;
  militaryStatus: string | null;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPrimaryPhone: string;
  emergencyPrimaryPhoneType: string;
  emergencyAlternatePhone: string;
  emergencyAlternatePhoneType: string;
  emergencyRelationship: number | null;
}

export interface IPastEmployeeFormModel {
  demographicDetails: {
    gender: string;
    ethnicityId: string;
    raceId: string;
    citizenshipId: string;
    maritalStatusId: string;
    militaryStatusId: string;
  };
  emergencyContact: {
    firstName: string;
    lastName: string;
    primaryPhoneNumber: {
      phoneNumber: string;
      typeId: number;
    };
    alternatePhoneNumber: {
      phoneNumber: string;
      typeId: number;
    } | null;
    relationshipId: number;
    relationship: string;
  };
}
